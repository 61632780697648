import { defineStore } from "pinia";
import { useTeamStore } from "./team";

export const useAccountStore = defineStore("account", {
  state: () => ({
    account: false,
    checkout: {
      url: "",
    },
    portal: {
      url: "",
    },
    billing: {},
  }),
  actions: {
    dismissNotice() {
      this.account = false;
    },
    async getCheckout(payload) {
      const teamStore = useTeamStore();
      const { token } = useAuth();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/account/billing/checkout`,
        {
          method: "POST",
          body: {
            id: teamStore.$state.activeTeam._id,
            tenant: teamStore.$state.activeTeam.tenantId,
            customer: teamStore.$state.activeTeam.billing?.customer_id || "",
            plan: payload.plan,
            addons: payload.addons,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (fetch) {
        this.checkout.url = fetch;
      }
    },
    async previewPlan(payload) {
      const teamStore = useTeamStore();
      const { token } = useAuth();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/account/billing/preview`,
        {
          method: "POST",
          body: {
            id: teamStore.$state.activeTeam._id,
            tenant: teamStore.$state.activeTeam.tenantId,
            customer: teamStore.$state.activeTeam.billing?.customer_id || "",
            plan: payload.plan,
            addons: payload.addons,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (fetch) {
        this.checkout.url = fetch;
      }
    },
    async changePlan() {
      const teamStore = useTeamStore();
      const { token } = useAuth();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/account/billing/change`,
        {
          method: "POST",
          body: {
            id: teamStore.$state.activeTeam._id,
            tenant: teamStore.$state.activeTeam.tenantId,
            customer: teamStore.$state.activeTeam.billing?.customer_id || "",
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (fetch) {
        this.checkout.url = fetch;
      }
    },
    async getPortal() {
      const teamStore = useTeamStore();
      const { token } = useAuth();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/account/billing/portal`,
        {
          method: "POST",
          body: {
            id: teamStore.$state.activeTeam.tenantId,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (fetch) {
        this.portal.url = fetch;
      }
    },
    async billingDetails() {
      const teamStore = useTeamStore();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/account/billing/details`,
        {
          //   query: { test: "campaign", status: colorStore.$state.activated },
          params: { tenant: teamStore.$state.activeTeam.tenantId },
        }
      );
      if (fetch) {
        if (fetch.billing) {
          this.billing = fetch.billing;
          this.billing.renews_on = fetch.subscription.current_period_end;
        } else {
          this.billing.status = fetch;
          this.billing.active = false;
          this.billing.plan = {
            base_plan: "trial",
            quotas: {
              forms: "500",
              users: "1",
              websites: "0",
            },
          };
        }
      }
    },
  },
});
